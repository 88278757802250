import React, { useState, useEffect } from "react";
import { collection, getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { UserAuth } from "../../context/AuthContext";
import JobCard from "../../components/cards/JobCard";
import { Link } from "react-router-dom";
import styles from "../../css/minhaconta.module.css";

const InvitedTrades = () => {
  const [jobs, setJobs] = useState([]);
  const { user, loading } = UserAuth(); // Ensure loading state is available

  const jobCollection = collection(db, "jobs");

  useEffect(() => {
    if (loading || !user?.invitedJobs) return;

    console.log(user);

    const fetchJobs = async () => {
      const jobsData = [];
      const userShortlistedJobs = user?.shortlistedJobs || [];
      const userHiredJobs = user?.hiredJobs || [];

      for (const jobId of user.invitedJobs) {
        if (
          !userShortlistedJobs.includes(jobId) &&
          !userHiredJobs.includes(jobId)
        ) {
          const jobRef = doc(jobCollection, jobId);
          const jobSnapshot = await getDoc(jobRef);

          if (jobSnapshot.exists()) {
            jobsData.push({ ...jobSnapshot.data(), id: jobSnapshot.id });
          }
        }
      }

      setJobs(jobsData);
    };

    fetchJobs();
  }, [user]); // Run effect only when `user` changes

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.detalhesContainer}>
      <h1>Trabalhos a que fui convidado</h1>
      {jobs.length === 0 ? (
        <p>Ainda sem atividade</p>
      ) : (
        jobs.map((job) => (
          <Link
            key={job.id}
            style={{ textDecoration: "none" }}
            to={`/meustrabalhos/${job.id}`}
            state={{ job }}
          >
            <JobCard value={{ job, user }} />
          </Link>
        ))
      )}
    </div>
  );
};

export default InvitedTrades;
