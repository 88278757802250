import React from "react";
import { Link } from "react-router-dom";
import styles from "../css/home.module.css";
import workers from ".././imgs/headerImg.png";
import handshake from ".././imgs/handshake.png";

const Home = () => {
  return (
    <div>
      {/* <div className={styles.paper}>
        <img src={paper} />
      </div> */}
      <div className={styles.divCabecalho}>
        <div>
          <h1 className={styles.title}>
            A melhor forma<br></br> <b>de encontrar</b>
            <br></br> trabalhadores.
          </h1>
          <h3 className={styles.subtitle}>
            Ajudamos donos de casas a encontrar o trabalhador ideal e bons
            trabalhadores a ter sucesso.
          </h3>

          <div className={styles.botoes}>
            <Link
              to="/registrar-como-comerciante"
              style={{ textDecoration: "none" }}
            >
              <li className={styles.btnRegister}>
                <h3 style={{ fontFamily: "DM Sans" }}>
                  Registar como Trabalhador
                </h3>
              </li>
            </Link>
            <Link to="/publicar-trabalho" style={{ textDecoration: "none" }}>
              <li className={styles.btnPostJob}>
                <h3>Criar Trabalho</h3>
              </li>
            </Link>
          </div>
        </div>
        <div className={styles.workers_side}>
          <img className={styles.imgWorkers} src={workers} />
        </div>
      </div>
      <div className={styles.zonaComecar}>
        <h1 className={styles.titles}>
          Como posso<br></br>
          <b>começar</b>
        </h1>
        <div className={styles.divAboutUs}>
          <div className={styles.divAboutUsFilho}>
            <header>
              <h1 className={styles.numberPasso}>1</h1>
              <div>
                <h5>Publique o seu trabalho</h5>
                <p>
                  Descreva a sua necessidade e notificaremos profissionais
                  qualificados na sua área. É rápido, simples e gratuito!
                </p>
              </div>
            </header>
          </div>
          <div className={styles.divAboutUsFilho}>
            <header>
              <h1 className={styles.numberPasso}>2</h1>
              <div>
                <h5>Descreva o seu trabalho</h5>
                <p>
                  Diga-nos o que precisa e conectaremos você com profissionais
                  qualificados na sua região. É fácil e sem custo!
                </p>
              </div>
            </header>
          </div>
          <div className={styles.divAboutUsFilho}>
            <header>
              <h1 className={styles.numberPasso}>3</h1>
              <div>
                <h5>Reveja e escolha</h5>
                <p>
                  Confira os detalhes do seu pedido e escolha entre os
                  profissionais qualificados que responderem. Tudo de forma
                  simples e gratuita!
                </p>
              </div>
            </header>
          </div>
        </div>
        <Link to="/publicar-trabalho" style={{ textDecoration: "none" }}>
          <button
            style={{ backgroundColor: "#272727" }}
            className={styles.verComoFuncionaBtn}
          >
            Ver como funciona
          </button>
        </Link>
      </div>
      <div className={styles.divCabecalho}>
        <div className={styles.espreitarBreak}>
          <h1 className={styles.title}>
            Vamos<br></br> <b>espreitar</b>
          </h1>
          <h3 className={[styles.subtitle]}>
            Ajudamos donos de casas a encontrar o trabalhador ideal, e bons
            trabalhadores a ter sucesso.
          </h3>

          <div>
            <Link
              to="/registrar-como-comerciante"
              style={{ textDecoration: "none" }}
            >
              <li className={styles.btnApply}>
                <h3>Juntar-me agora</h3>
              </li>
            </Link>
          </div>
        </div>
        <div className={styles.workers_side2}>
          <img src={handshake} />
        </div>
      </div>
    </div>
  );
};

export default Home;
